
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../../style/globalColorVars.scss';

.card-image-el {
 // Adds background tramlines for any 4x3 image being rendered
 //background-color: $background-alt;
 width: 100%;
 max-width: 100%;
}

.card-image-el.series.logo,
.card-image-el.summits.logo {
 height: 28px;
}

